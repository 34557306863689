import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import categoryReducer from './slices/category';
import statReducer from './slices/stats';
import cartReducer from './slices/cart';
import itemReducer from './slices/item';
import choiceGroupReducer from './slices/choiceGroup';
import menuReducer from './slices/menu';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import areaReducer from './slices/area';
import desiginationReducer from './slices/desigination';
import venueUserReducer from './slices/venueUser';
import venueReducer from './slices/venue';
import tableReducer from './slices/table';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const cateogoryPersistConfig = {
  key: 'category',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const statPersistConfig = {
  key: 'stat',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const cartPersistConfig = {
  key: 'posCart',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const itemPersistConfig = {
  key: 'item',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const choiceGroupPersistConfig = {
  key: 'choiceGroup',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const menuPersistConfig = {
  key: 'menu',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const areaPersistConfig = {
  key: 'area',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const desiginationPersistConfig = {
  key: 'desigination',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const venueUserPersistConfig = {
  key: 'venueUser',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const venuePersistConfig = {
  key: 'venue',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const tablePersistConfig = {
  key: 'table',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  category: persistReducer(cateogoryPersistConfig, categoryReducer),
  stat: persistReducer(statPersistConfig, statReducer),
  area: persistReducer(areaPersistConfig, areaReducer),
  desigination: persistReducer(desiginationPersistConfig, desiginationReducer),
  venueUser: persistReducer(venueUserPersistConfig, venueUserReducer),
  venue: persistReducer(venuePersistConfig, venueReducer),
  table: persistReducer(tablePersistConfig, tableReducer),
  item: persistReducer(itemPersistConfig, itemReducer),
  choiceGroup: persistReducer(choiceGroupPersistConfig, choiceGroupReducer),
  menu: persistReducer(menuPersistConfig, menuReducer),
  posCart: persistReducer(cartPersistConfig, cartReducer),
});

export default rootReducer;
