import { Dialog } from "@mui/material";

export default function ConfirmDialog({
  width,
  open,
  onClose,
  children,
  ...other
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={`${width}`}
      open={open}
      onClose={onClose}
      {...other}
    >
      {children}
    </Dialog>
  );
}
