import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  openModal: false,
  openModalCartProduct: false,
  openModalDineIn: false,
  openModalTakeAway: false,
  openModalTakeAwayCustomAmount: false,
  openModalOrder: false,
  openModalPOSList: false,
  openModalDiscount: false,
  selectedEventId: null,
  selectedRange: null,
  levelId: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = state.events.map((event) => {
        if (event.id === action.payload.id) {
          return action.payload;
        }
        return event;
      });
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const eventId = action.payload;
      state.events = state.events.filter((event) => event.id !== eventId);
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.openModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.openModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    onOpenModalPayInvoice(state) {
      state.openModalPayInvoice = true;
    },

    onOpenModalGiveCredit(state) {
      state.openModalGiveCredit = true;
    },

    onOpenModalGiveRefund(state) {
      state.openModalGiveRefund = true;
    },

    onOpenModalCartProduct(state) {
      state.openModalCartProduct = true;
    },

    onOpenModalDineIn(state) {
      state.openModalDineIn = true;
    },

    onOpenModalTakeAway(state) {
      state.openModalTakeAway = true;
    },

    onOpenModalTakeAwayCustomAmount(state) {
      state.openModalTakeAwayCustomAmount = true;
    },

    onOpenModalOrder(state) {
      state.openModalOrder = true;
    },

    onOpenModalPOSListPopup(state) {
      state.openModalPOSList = true;
    },

    onOpenModalDiscountPopup(state) {
      state.openModalDiscount = true;
    },

    setLevelId(state, action) {
      state.levelId = action.payload;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.openModalPayInvoice = false;
      state.openModalGiveCredit = false;
      state.openModalGiveRefund = false;
      state.openModalCartProduct = false;
      state.openModalPOSList = false;
      state.openModalDiscount = false;
      state.openModalOrder = false;
      state.openModalDineIn = false;
      state.openModalTakeAway = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    onCloseModalCustomAmount(state) {
      state.openModalTakeAwayCustomAmount = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onOpenModal,
  onOpenModalPayInvoice,
  onOpenModalGiveCredit,
  onOpenModalGiveRefund,
  onOpenModalCartProduct,
  onOpenModalDineIn,
  onOpenModalTakeAway,
  onOpenModalTakeAwayCustomAmount,
  onOpenModalOrder,
  onCloseModal,
  onCloseModalCustomAmount,
  onOpenModalPOSListPopup,
  onOpenModalDiscountPopup,
  selectEvent,
  selectRange,
  setLevelId,
} = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/calendar/events");
      dispatch(slice.actions.gesetLevelIdtEventsSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/calendar/events/new", newEvent);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, event) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/calendar/events/update", {
        eventId,
        event,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post("/api/calendar/events/delete", { eventId });
      dispatch(slice.actions.deleteEventSuccess(eventId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
