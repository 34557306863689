import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  posCarts: [],
  posCart: null,
};

const slice = createSlice({
  name: "posCart",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getCartsSuccess(state, action) {
      state.isLoading = false;
      state.posCarts = JSON.parse(JSON.stringify(action.payload));
    },

    reRenderCartsSuccess(state, action) {
      state.posCart.map((cg) => {
        if (cg.id === action.payload) {
          cg.selected = true;
        }
      });
    },

    // GET CATEGORY
    getCartSuccess(state, action) {
      state.isLoading = false;
      state.posCart = JSON.parse(JSON.stringify(action.payload));
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getCarts(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/posCart?levelId=${levelId}&venueId=${venueId}`
      );
      dispatch(slice.actions.getCartsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reRenderCarts(id) {
  console.log("called");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.reRenderCartsSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCart(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/posCart?levelId=${levelId}&venueId=${venueId}`
      );
      dispatch(slice.actions.getCartSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
