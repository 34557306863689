// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    report: path(ROOTS_DASHBOARD, '/report'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  desigination: {
    root: path(ROOTS_DASHBOARD, '/desigination'),
    new: path(ROOTS_DASHBOARD, '/desigination/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/desigination/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  menu: {
    list: path(ROOTS_DASHBOARD, '/menu/list'),
    newCategory: path(ROOTS_DASHBOARD, '/category/new'),
    newItem: path(ROOTS_DASHBOARD, '/item/new'),
    display: path(ROOTS_DASHBOARD, '/menu/display'),
  },
  category: {
    new: path(ROOTS_DASHBOARD, '/category/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/category/${id}/edit`),
  },
  food: {
    new: path(ROOTS_DASHBOARD, '/food/new'),
  },
  item: {
    new: path(ROOTS_DASHBOARD, '/item/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/item/${id}/edit`),
  },
  choice: {
    new: path(ROOTS_DASHBOARD, '/choice/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/choice/${id}/edit`),
  },
  drink: {
    new: path(ROOTS_DASHBOARD, '/drink/new'),
  },
  customer: {
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    new: path(ROOTS_DASHBOARD, '/customer/new'),
    account: path(ROOTS_DASHBOARD, '/customer/account'),
  },
  pos: {
    list: path(ROOTS_DASHBOARD, '/pos'),
    orderList: path(ROOTS_DASHBOARD, '/pos/list'),
  },
  orders: {
    list: path(ROOTS_DASHBOARD, '/orders'),
  },
  kitchen: {
    list: path(ROOTS_DASHBOARD, '/kitchen'),
  },
  bar: {
    list: path(ROOTS_DASHBOARD, '/bar/list'),
    new: path(ROOTS_DASHBOARD, '/bar/new'),
    level: path(ROOTS_DASHBOARD, '/bar/level'),
    account: path(ROOTS_DASHBOARD, '/bar/account'),
  },
  table: {
    list: path(ROOTS_DASHBOARD, '/table/list'),
    new: path(ROOTS_DASHBOARD, '/table/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/table/${id}/edit`),
    level: path(ROOTS_DASHBOARD, '/table/level'),
    account: path(ROOTS_DASHBOARD, '/table/account'),
  },
  area: {
    new: path(ROOTS_DASHBOARD, '/area/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/area/${id}/edit`),
  },
  reservation: {
    list: path(ROOTS_DASHBOARD, '/reservation/list'),
    calendar: path(ROOTS_DASHBOARD, '/reservation/calendar'),
    new: path(ROOTS_DASHBOARD, '/reservation/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/reservation/${name}/edit`),
    level: path(ROOTS_DASHBOARD, '/reservation/level'),
    account: path(ROOTS_DASHBOARD, '/reservation/account'),
  },
  order: {
    list: path(ROOTS_DASHBOARD, '/order/list'),
    new: path(ROOTS_DASHBOARD, '/order/new'),
    account: path(ROOTS_DASHBOARD, '/order/account'),
    detail: (id) => path(ROOTS_DASHBOARD, `/order/${id}/detail`),
  },
  complaint: {
    list: path(ROOTS_DASHBOARD, '/complaint/list'),
    new: path(ROOTS_DASHBOARD, '/complaint/new'),
    account: path(ROOTS_DASHBOARD, '/complaint/account'),
    detail: (id) => path(ROOTS_DASHBOARD, `/complaint/${id}/detail`)
  },
  notification: {
    list: path(ROOTS_DASHBOARD, '/notification/list'),
    // new: path(ROOTS_DASHBOARD, '/notification/new'),
    // account: path(ROOTS_DASHBOARD, '/notification/account'),
    // detail: (id) => path(ROOTS_DASHBOARD, `/notification/${id}/detail`)
  },

  settings: {
    list: path(ROOTS_DASHBOARD, '/settings/list'),
    new: path(ROOTS_DASHBOARD, '/settings/new'),
    account: path(ROOTS_DASHBOARD, '/settings/account'),
  },

};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
