import { Box, Typography } from "@mui/material";
const PolicyModalHeader = () => {
  return (
    <Box>
      <Typography
        sx={{
          color: "#222",
          fontWeight: "700",
          fontSize: "25px",
        }}
      >
        Terms & Privacy Policy
      </Typography>
    </Box>
  );
};

export default PolicyModalHeader;
