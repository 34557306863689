import PropTypes from "prop-types";
import { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, AppBar, Toolbar, Container, Link } from "@mui/material";
import useOffSetTop from "../../hooks/useOffSetTop";
import { bgBlur } from "../../utils/cssStyles";
import { HEADER } from "../../config-global";
import Logo from "../../components/logo";
//

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <AppBar
      position="static"
      ref={carouselRef}
      color="transparent"
      sx={{ boxShadow: 0 }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
          <Logo />

          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="contained"
            target="_blank"
            rel="noopener"
            href="https://venue.egora.pk"
          >
            Sign In
          </Button>
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
